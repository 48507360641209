// @ts-nocheck

import { formatUnits } from "@ethersproject/units";
import { ChainId, FACTORY_ADDRESS } from "@zinuswap/sdk";

import { getUnixTime, sub } from "date-fns";
import { gql } from "graphql-request";
import { GetStaticProps } from "next";
import { SWRConfig } from "swr";
import { getZinuVaultAddress } from "utils/addressHelpers";
import { getZinuContract } from "utils/contractHelpers";
import { getBlocksFromTimestamps } from "utils/getBlocksFromTimestamps";
import {
  bitQueryServerClient,
  infoServerClient,
} from "utils/graphql";
import Swap from "../views/Swap";

const IndexPage = ({ totalTx30Days, addressCount30Days, tvl }) => {
  return (
    <SWRConfig
      value={{
        fallback: {
          totalTx30Days,
          addressCount30Days,
          tvl,
        },
      }}
    >
      <Swap />
    </SWRConfig>
  );
};

// Values fetched from TheGraph and BitQuery jan 24, 2022
const txCount = 54780336;
const addressCount = 4425459;

const tvl = 6082955532.115718;

export const getStaticProps: GetStaticProps = async () => {
  const totalTxQuery = gql`
    query TotalTransactions($id: ID!, $block: Block_height) {
      zadiFactory(id: $id, block: $block) {
        totalTransactions
      }
    }
  `;

  const days30Ago = sub(new Date(), { days: 30 });

  const results = {
    totalTx30Days: txCount,
    addressCount30Days: addressCount,
    tvl,
  };

  if (process.env.SF_HEADER) {
    try {
      const [days30AgoBlock] = await getBlocksFromTimestamps([
        getUnixTime(days30Ago),
      ]);

      if (!days30AgoBlock) {
        throw new Error("No block found for 30 days ago");
      }

      const totalTx = await infoServerClient.request(totalTxQuery, {
        id: FACTORY_ADDRESS[ChainId.BSC],
      });
      const totalTx30DaysAgo = await infoServerClient.request(
        totalTxQuery,
        {
          block: {
            number: days30AgoBlock.number,
          },
          id: FACTORY_ADDRESS[ChainId.BSC],
        }
      );

      if (
        totalTx?.zadiFactory?.totalTransactions &&
        totalTx30DaysAgo?.zadiFactory?.totalTransactions &&
        parseInt(totalTx.zadiFactory.totalTransactions) >
          parseInt(
            totalTx30DaysAgo.zadiFactory.totalTransactions
          )
      ) {
        results.totalTx30Days =
          parseInt(totalTx.zadiFactory.totalTransactions) -
          parseInt(
            totalTx30DaysAgo.zadiFactory.totalTransactions
          );
      }
    } catch (error) {
      if (process.env.NODE_ENV === "production") {
        console.error("Error when fetching total tx count", error);
      }
    }
  }

  const usersQuery = gql`
    query userCount($since: ISO8601DateTime, $till: ISO8601DateTime) {
      ethereum(network: bsc) {
        dexTrades(
          exchangeName: { in: ["ZombieInu", "ZombieInu v2"] }
          date: { since: $since, till: $till }
        ) {
          count(uniq: senders)
        }
      }
    }
  `;

  if (process.env.BIT_QUERY_HEADER) {
    try {
      const result = await bitQueryServerClient.request(usersQuery, {
        since: days30Ago.toISOString(),
        till: new Date().toISOString(),
      });
      if (result?.ethereum?.dexTrades?.[0]?.count) {
        results.addressCount30Days =
          result.ethereum.dexTrades[0].count;
      }
    } catch (error) {
      if (process.env.NODE_ENV === "production") {
        console.error("Error when fetching address count", error);
      }
    }
  }

  try {
    const result = await infoServerClient.request(gql`
      query tvl {
        zadiFactories(first: 1) {
          totalLiquidityUSD
        }
      }
    `);
    const zinu = await (
      await fetch(
        "https://farms.zombieinu-swap.workers.dev/price/zinu"
      )
    ).json(); // TODO-SD
    const { totalLiquidityUSD } = result.zombieInuFactories[0];
    // const zinuVaultV2 = getZinuVaultAddress(ChainId.BSC)
    // const zinuContract = getZinuContract(ChainId.BSC)
    // const totalZinuInVault = await zinuContract.balanceOf(zinuVaultV2)
    results.tvl = parseFloat(totalLiquidityUSD);
  } catch (error) {
    if (process.env.NODE_ENV === "production") {
      console.error("Error when fetching tvl stats", error);
    }
  }

  return {
    props: results,
    revalidate: 60 * 60 * 24 * 30, // 30 days
  };
};

IndexPage.chains = [];

export default IndexPage;
